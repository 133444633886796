@import 'libs/bootstrap-sass/scss/bootstrap';
@import 'libs/font-awesome/scss/font-awesome';
@import 'typography';

 // ----------- Write Styles Here -------------

html {
	
}

body {	
	font-family: comfortaa;
	font-weight: lighter;
}


section {
	height: calc(100vh - 50px);
	padding-top: 50px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 50px;
	flex-direction: column;
}

section#above_fold {
	padding-top: 0px;
}


.text_center {
	text-align: center;
}

.text_right {
	text-align: right;
}

.vertical_center {
	display: flex;
	align-items: center;
}

.full_width {
	width: 100%;
}

.col_50 {
	width: 50%;
}

.col_75 {
	width: 75%;
}

.col_25 {
	width: 25%;
}

.wide_padding {
	padding: 0px 5vw;
}

.thin_padding {
	padding: 0px 1vw;
}

.max_300 {
	max-width: 300px;
}

.max_400 {
	max-width: 400px;
}

.max_600 {
	max-width: 600px;
}

.clear {
	clear: both;
}

.float_right {
	float: right;
}

.flex_column {
	flex-direction: column;
	display: flex;
}

.flex_row {
	display: flex;
	flex-direction: row;
}

.baseline {
	align-items: baseline;
}

// --------  RH - LOGO - SVG  -----------
svg {
	max-height: 50vh;
	max-width: 100%;
	padding: 10px;
}


#ryan_face, #work svg {
	max-height: 70vh;
	padding: 20px;
}


#svg_rh_logo .path{
	fill:none;
	stroke:#000000;
	stroke-width:10;
	stroke-miterlimit:10;
	stroke-linecap: round;
	stroke-dasharray: 1000;
	stroke-dashoffset: 1000;
}

#ryan_face .st0, #ryan_face .st1, #ryan_face .st2, #ryan_face .st3, #ryan_face .st4 {
	stroke-linecap: round;
	stroke-dasharray: 1000;
	stroke-dashoffset: 1000;
}


.play_me{
	.path {
		fill:#FFFFFF00;
		animation: dash 3s ease-out forwards;
	}

	&#ryan_face .st0, &#ryan_face .st1, &#ryan_face .st2, &#ryan_face .st3, &#ryan_face .st4 {
		animation: dash 4s ease-out forwards;
	}

	.path.form_input {
		animation: dash 1.5s ease-out forwards;
	}
}



@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
// -------- END  RH - LOGO - SVG  -----------




 // ------------ NAV ------------
nav.navbar {
	width: 80vw;
	max-width: 800px;
	margin: 0 auto;
	padding: 1vh 0;
	margin-top: -50px;
	ul {
		padding: 0;
		margin: 0;
		display: flex;
	}
	li {
		display: inline-block;
		width: 33.33%;
		text-align: center;
	}
	a.nav-link {
		color: #000;
		opacity: .6;
		text-decoration: none;
		transition: all .5s ease;
		padding: 0 20px;
		display: inline-block;
		font-size: get-size(map14);
		backface-visibility: hidden;
	}
	a:active, a:hover, a.active{
		color: #000;
		opacity: .9;
		text-decoration: none;
	}
}

.bullet {
    position: absolute;
    bottom: 0;
    left: 50%;
    font-size: get-size(map12);
    transform: translateX(-50%);
    opacity: 0;
    transition: all .5s ease;
    z-index: -1;
    bottom: 0px;
    margin-bottom: -7px;
}



 // ------ STATS --------

#stats {

	.stats_container {
		display: flex;
		justify-content: space-between;
		width: 100%;
		max-width: 600px;
		padding: 10px 30px;

		.stat_col {
			text-align: center;

			.stat_bar {
				height: 50vh;
				display: flex;
    			align-items: flex-end;

				.stat_line {
					height: 0px;
					transition: all 3s ease;
					width: 2px;
					margin: 0 auto;
					background: rgba(0,0,0,.3);
				}
			}

			.stat_label {
				font-size: get-size(map10);
				white-space: nowrap;
			}
			
		}	
	}

	.stats_container.play_me .stat_col{
		.html_line {
			height: 92%;
		}
		.scss_line {
			height: 95%;
		}
		.js_line {
			height: 88%;
		}
		.php_line {
			height: 90%;
		}
		.wp_line {
			height: 97%;
		}
		.psd_line {
			height: 95%;
		}
		.acf_line {
			height: 95%;
		}
		.laravel_line {
			height: 60%;
		}
	}
}



#work {

	a, a:visited{
		color: rgba(0,0,0, .7);
		font-size: get-size(map14);
		transition: all .5s ease;
		text-decoration:none;
	}
	a:hover, a:active {
		color: rgba(0,0,0, 1);
		text-decoration:none;
	}

	svg {
		.st0{fill:none;stroke:#000;stroke-width:5;stroke-miterlimit:10;}
		.st1{fill:none;stroke:#000;stroke-width:2;stroke-miterlimit:10;}
	}

	.dark {
		color: white;

		a {
			color: rgba(255,255,255, .7);
		}

		a:hover, a:active {
			color: rgba(255,255,255, 1);
		}

		svg {
			.st0, .path, .st1{stroke:#FFF; fill:none;}
		}
	}
}

#contact {
	form {
		max-width: 600px;
	}
	input, textarea {
		width: 100%;
		background: rgba(0,0,0,0);
	}
	input::placeholder {
		color: rgba(255,255,255,0.7);
	}

	input, textarea, .radio_text, .radio_bullet {
		opacity: 0;
		transition: opacity 2s ease 1s, color .5s ease;
	}

	.play_me input, .play_me textarea, .play_me .radio_text, .play_me #submit, .play_me .radio_bullet {
		opacity: 1 !important;
	}

	.input_block {
		position:relative;
		width: 100%;
		padding-bottom: calc(100%/6);
		margin-top: 15px;
		input, textarea {
			position:absolute;
			height: 100%;
			border: none;
			padding: 10px 20px;
			z-index: 10;
			color: white;
		}
		input:focus, textarea:focus {
		    outline: none;
		    color: #96eaf3;
		}
		#submit {
			position:absolute;
			height: 100%;
			width: 100%;
			padding: 10px 20px;
			z-index: 10;
			color: white;
			transition: opacity 2s ease 1s, color .5s ease, background-color .5s ease;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			border-radius: 22px;
			opacity: 0;
		}

		#submit:hover{
			background: #96eaf3;
			color: rgb(50,50,50);
		}
		
	}

	.textarea_block {
		padding-bottom: calc(100%/4.25);
	}

	.btn_block {
		padding-bottom: calc(100%/1.5);
	}

	.radio_option {
		position:relative;
		width: 100%;
		padding-bottom: calc(100%/12);
		margin-top: 15px;
		.radio_text {
			position: absolute;
			height: 100%;
			color: white;
			font-size: get-size(radio);
		}
		cursor: pointer;
	}



	svg {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		padding: 0px;
		height: 100%;
		.path, path {
			fill:#FFFFFF00;stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;
		}
	}

	svg.radio_outer_circle {
	    right: 0;
	    width: 24px;
	    left: inherit;
	}

	.active .radio_outer_circle .path {
		stroke: #96eaf3;
	}

	.radio_container {
		position: relative;
	}

	.radio_bullet {
	    position: absolute;
	    height: 23px;
	    top: 0;
	    right: 8px;
	    font-size: 12px;
	    padding: 2px 0;
	    opacity: 0;
	    transition: all .5s ease, opacity 2s ease 1s;
	    z-index: -1;
	    bottom: 0px;
	}

}


footer {
	max-width: 400px;
	text-align: center;
	padding: 15px;
	border-top: 1px solid rgba(255,255,255,.1);
	color: rgba(255,255,255,.2);
	font-size: get-size(map10);
	margin: 0 auto;
}

img {
	max-width: 100%;
}

.close-modal{
	position: absolute;
    right: -15px;
    top: -20px;
    color: white;
    cursor: pointer;
}

.modal-content {
	border: 0px;
	border-radius: 0px;
}

.textbox {
	max-width: 600px;
}

.grey {
	color: #555;
}

.grecaptcha-badge {
	opacity: 0;
}