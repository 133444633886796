
@font-face {
	font-family: "comfortaa";
	font-weight: bold;
	src: url('../fonts/Comfortaa-Bold.eot?'); /* IE9 Compat Modes */
  	src: url('../fonts/Comfortaa-Bold.otf') format('opentype'), 
       url('../fonts/Comfortaa-Bold.woff') format('woff'), /* Super Modern Browsers */
       url('../fonts/Comfortaa-Bold.ttf')  format('truetype') /* Safari, Android, iOS */
}
@font-face {
	font-family: "comfortaa";
	font-weight: normal;
	src: url('../fonts/Comfortaa-Regular.eot?'); /* IE9 Compat Modes */
  	src: url('../fonts/Comfortaa-Regular.otf') format('opentype'), 
       url('../fonts/Comfortaa-Regular.woff') format('woff'), /* Super Modern Browsers */
       url('../fonts/Comfortaa-Regular.ttf')  format('truetype') /* Safari, Android, iOS */
}
@font-face {
	font-family: "comfortaa";
	font-weight: lighter;
	src: url('../fonts/Comfortaa-Light.eot?'); /* IE9 Compat Modes */
  	src: url('../fonts/Comfortaa-Light.otf') format('opentype'), 
       url('../fonts/Comfortaa-Light.woff') format('woff'), /* Super Modern Browsers */
       url('../fonts/Comfortaa-Light.ttf')  format('truetype') /* Safari, Android, iOS */
}



/// Calculate the least square fit linear regression of provided values
/// @param {map} $map - A SASS map of viewport width and size value combinations
/// @return Linear equation as a calc() function
/// @example
///   font-size: least-squares-fit((576: 24, 768: 24, 992: 34));
@function get-size($size) {

	// Add or replace breakpoints for theme here:
	$map: map-get((
		map45 : (320: 35, 768: 40, 992: 45, 1600: 50),
		map32 : (320: 26, 768: 30, 992: 32, 1600: 36),
		map28 : (320: 22, 768: 25, 992: 28, 1600: 35),
		map25 : (320: 20, 768: 23, 992: 25, 1600: 30),
		map22 : (320: 17, 768: 19, 992: 22, 1600: 26),
		map16 : (320: 13, 768: 14, 992: 16, 1600: 20),
		map14 : (320: 12, 768: 13, 992: 14, 1600: 17),
		map12 : (320: 10, 768: 11, 992: 12, 1600: 15),
		map10 : (320: 8, 768: 9, 992: 10, 1600: 12),
		radio : (320: 10, 768: 12, 992: 14, 1600: 13)
	), $size);

	// Get the number of provided breakpoints
	$length: length(map-keys($map));

	// Error if the number of breakpoints is < 2
	@if ($length < 2) {
	@error "leastSquaresFit() $map must be at least 2 values"
	}

	// Calculate the Means
	$resTotal: 0;
	$valueTotal: 0;
	@each $res, $value in $map {
	$resTotal: $resTotal + $res;
	$valueTotal: $valueTotal + $value;
	}
	$resMean: $resTotal/$length;
	$valueMean: $valueTotal/$length;

	// Calculate some other stuff
	$multipliedDiff: 0;
	$squaredDiff: 0;
	@each $res, $value in $map {

	// Differences from means
	$resDiff: $res - $resMean;
	$valueDiff: $value - $valueMean;

	// Sum of multiplied differences
	$multipliedDiff: $multipliedDiff + ($resDiff * $valueDiff);

	// Sum of squared resolution differences
	$squaredDiff: $squaredDiff + ($resDiff * $resDiff);
	}

	// Calculate the Slope
	$m: $multipliedDiff / $squaredDiff;

	// Calculate the Y-Intercept
	$b: $valueMean - ($m * $resMean);

	// Return the CSS calc equation
	@return calc(#{$m*100}vw + #{$b}px);
}



body {
	color: #000;
	line-height: 1.6;
	font-size: 16px;
}

h1,h2,h3,h4,h5,h6{
	line-height: 1.6;
	opacity: .6;
}

h1 {
	font-weight:normal;
	padding: 8vh 0px 0px;
	.h-name {
		display:block;
		font-size: get-size(map16); 
		font-size: 3vmin;
	}

	.h-role {
		display:block;
		margin-top: 1vh;
		font-size: get-size(map28); 
		font-size: 4vmin;
	}
}

h2 {
	font-size: get-size(map22); 
}

p {
	font-size: get-size(map16);
	opacity: .5;
}

a, a:visited{
	color: rgba(0,0,0, .7);
	text-decoration:none;
}
a:hover, a:active {
	color: rgba(0,0,0, 1);
	text-decoration:none;
}